import type { PolicyOffer } from '../quote-offer-information-dimension.js'
import { offersDescriptions } from '../shared-quote-offer-information.js'
import type { MrpGuaranteesType } from './mrph-guarantees-typenames.js'

export const mrpPolicyOffer: PolicyOffer = offersDescriptions['assuranceDommagesBiens']

export const mrphGuaranteeOfferFactory: {
  [key in MrpGuaranteesType]: PolicyOffer
} = {
  Attentat: mrpPolicyOffer,
  BrisGlace: mrpPolicyOffer,
  CatastropheNat: mrpPolicyOffer,
  DegatsEaux: mrpPolicyOffer,
  DommagesElec: mrpPolicyOffer,
  Emeute: mrpPolicyOffer,
  EvenementsClim: mrpPolicyOffer,
  Incendie: mrpPolicyOffer,
  InformatiqueMachine: mrpPolicyOffer,
  RespCivOccupant: mrpPolicyOffer,
  Vandalisme: mrpPolicyOffer,
  Vol: mrpPolicyOffer,
}
