import type { PolicyGuaranteeVisualPriority, PolicyOffer, RcdaGuarantee } from '../quote-offer-information-dimension.js'
import { offersDescriptions } from '../shared-quote-offer-information.js'

export const rcdaGuaranteesProductInformation: {
  [key in RcdaGuarantee]: {
    type: RcdaGuarantee
    name: string
    shortText: string
    fullText: string
    example: string
    priority: PolicyGuaranteeVisualPriority
    offer: PolicyOffer
  }
} = {
  rcDecennaleObligatoire: {
    type: 'rcDecennaleObligatoire',
    name: 'RC décennale obligatoire',
    offer: offersDescriptions['decennialCivilLiability'],
    priority: 'P1',
    shortText: 'Dommages pour des ouvrages d’habitation ou non.',
    fullText: `Dommages pour des ouvrages à usage d’habitation ou non.

Prise en charge du paiement des travaux de réparation de l’ouvrage à la réalisation duquel l’assuré a contribué, ainsi que des ouvrages existants, totalement incorporés dans l’ouvrage neuf et qui en deviennent techniquement indivisibles.

- Les travaux de réparation.
- Remplacement des ouvrages.
- Les travaux de démolition.
- Déblaiement.
- Dépose ou démontage.`,
    example: `Des problèmes sur les fondations que vous avez réalisées, provoquent des fissures importantes qui menacent la solidité de l’ouvrage 5 ans après sa réception.`,
  },
  rcDecennaleSousTraitance: {
    type: 'rcDecennaleSousTraitance',
    name: 'RC décennale sous-traitance',
    offer: offersDescriptions['decennialCivilLiability'],
    priority: 'P1',
    shortText: 'Dommages pour des ouvrages à usage d’habitation ou non en tant que sous-traitant.',
    fullText: `Dommages pour des ouvrages à usage d’habitation ou non en tant que sous-traitant.

Prise en charge du paiement des travaux de réparation, des dommages matériels affectant l’ouvrage à la réalisation duquel l’assuré a contribué en tant que sous-traitant.

- Les travaux de réparation.
- Remplacement des ouvrages.
- Les travaux de démolition.
- Déblaiement.
- Dépose ou démontage.`,
    example:
      'Suite aux travaux de réfection de toiture d’une maison que vous avez exécutés en tant que sous-traitant, des infiltrations d’eau apparaissent rendant l’ouvrage inhabitable.',
  },
  rcDecennaleNonObligatoire: {
    type: 'rcDecennaleNonObligatoire',
    name: 'RC décennale non obligatoire',
    offer: offersDescriptions['decennialCivilLiability'],
    priority: 'P1',
    shortText: 'Dommages pour les ouvrages liés aux travaux publics.',
    fullText: `Dommages pour les ouvrages liés aux travaux publics.

Prise en charge du paiement des travaux de réparation des dommages matériels affectant, après Réception, l’ouvrage non soumis à obligation d’assurance, à la réalisation duquel l’assuré a contribué, et compromettant la solidité de cet ouvrage.

- Les travaux de réparation.
- Remplacement des ouvrages.
- Les travaux de démolition.
- Déblaiement.
- Dépose ou démontage.`,
    example:
      'Sur l’un de vos chantiers, des problèmes sur les fondations que vous avez réalisées provoquent des fissures importantes qui menacent la solidité de l’ouvrage 5 ans après sa réception.',
  },
  rcAvantApresReception: {
    type: 'rcAvantApresReception',
    name: 'RC avant/après réception',
    offer: offersDescriptions['nonDecennialCivilLiability'],
    priority: 'P1',
    shortText: 'Dommages matériels et immatériels consécutifs suite à la livraison d’un chantier.',
    fullText: `Dommages corporels, dommages matériels ou dommages immatériels subis par des tiers du fait des travaux exécutés par l’assuré et/ou ses sous-traitants et notamment :
- Les dommages causés par incendie, explosion, accident ou dégât d'eau.
- Les dommages causés aux immeubles voisins.
- Les dommages matériels subis par les existants, avant la réception.
- Les dommages matériels subis par les biens confiés à l’assuré dans le cadre des activités assurées.
- Les dommages matériels causés par les sous-traitants de l’assuré.
- Les dommages corporels consécutifs à des dommages relevant d'autres garanties du contrat, acquises ou non.
- Les dommages immatériels consécutifs à des dommages corporels ou matériels garantis par le contrat.
- Les dommages immatériels non consécutifs.
- Les dommages résultant d'une atteinte accidentelle à l’environnement, lorsqu'ils surviennent après réception des travaux, les dommages résultant d'intoxication alimentaire provoquée par l'absorption d'aliments servis à autrui ou aux préposés de l’assuré.`,
    example:
      "Pendant des travaux de rénovation dans un immeuble résidentiel, un de vos ouvriers provoque accidentellement un incendie en utilisant une soudeuse. L'incendie se propage rapidement et endommage non seulement l'appartement en cours de rénovation, mais également la cage d’escalier et l’appartement voisin.",
  },
  biensSurChantier: {
    type: 'biensSurChantier',
    name: 'Bon fonctionnement de l’équipement',
    offer: offersDescriptions['equipmentsMaterials'],
    priority: 'P1',
    shortText: "La prise en charge des coûts de réparation ou de remplacement des éléments d'équipement dissociables.",
    fullText: `Équipement présent sur le chantier dissociable des ouvrages soumis à l’assurance obligatoire.

  Prise en charge du paiement des travaux de réparation des dommages matériels intermédiaires et affectant l’ouvrage de construction, lorsque ces dommages entraînent la mise en jeu de la garantie de bon fonctionnement durant les deux années qui suivent la réception.`,
    example:
      'Un dysfonctionnement de la VMC, que vous avez installée lors de la construction d’une maison, survient 18 mois après la réception de l’ouvrage.',
  },
}
