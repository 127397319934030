import type { EsRcphGuaranteeData } from './es-rcph-quote-offer-information'

export const allEsRcphGuaranteesAndOptions: EsRcphGuaranteeData[] = [
  {
    id: 'esRcphLossOfYourDocuments',
    name: 'Pérdida de sus documentos',
    example:
      'Eres un diseñador web independiente. Un día, el disco duro en el que almacena gran parte de sus datos deja de funcionar y sus datos parecen perdidos. ➡️ Cubrimos los costos de restaurar estos documentos (por ejemplo, llamar a un experto en recuperación de datos).',
    group: 'PI',
    shortDescription:
      'Cobertura para la restauración o reemplazo de documentos esenciales para su negocio o clientes bajo su custodia, siempre que no estén incluidos los costos relacionados con sus propios datos electrónicos y tenga la obligación legal de restaurar o reemplazar los documentos de los clientes.',
    description:
      'Cubrimos la pérdida de documentos esenciales para su actividad profesional o de clientes bajo su custodia, siempre que:a) No se prueba su negligencia.b) La pérdida no resulta de ningún acto deshonesto, ilícito o fraudulento cometido por sus empleados o cualquier otra persona o subcontratista contratados directamente por usted y bajo su supervisión.c) No tiene derecho a otra cobertura de seguro por esta pérdida, excepto el exceso aplicable sobre el monto pagado por dicho otro seguro.',
    scope: new Set(['TECH'] as const),
  },
  {
    id: 'esRcphPostDeliveryCivilLiability1',
    name: 'Responsabilidad civil después de la entrega',
    example:
      'Eres consultor y tu cliente decide demandarte por negligencia, acusándote de haber configurado mal el sistema, lo que provocó la pérdida de datos importantes. Por lo tanto, eres responsable de los daños causados ​​después de tu misión. ➡️ Cubrimos las indemnizaciones que puedas deber, los costes de defensa y las fianzas legales para las reclamaciones cubiertas.',
    group: 'GL',
    shortDescription:
      'Cobertura por daños causados ​​por productos entregados o trabajos realizados, fuera de su control.',
    description:
      'Cubrimos las indemnizaciones que pueda deber el asegurado por los daños corporales, daños materiales o sus consecuencias, causados ​​involuntariamente a terceros por los productos entregados o los trabajos realizados, una vez que estos ya no estén bajo su control, durante el período asegurado y en el ejercicio. de su actividad profesional.También pagamos los costos de defensa y las fianzas judiciales relacionadas con un reclamo cubierto. Sin embargo, no se cubrirán los costes relacionados con piezas no cubiertas.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphEmployerCivilLiability1',
    name: 'Responsabilidad civil del empleador',
    example:
      'Uno de sus empleados resulta lesionado en un accidente de trabajo ocurrido durante el período del seguro.  ➡️ También cubrimos gastos de defensa y fianzas legales necesarias.',
    group: 'GL',
    shortDescription:
      'Cobertura de indemnización por daños corporales sufridos por los empleados durante un accidente de trabajo.',
    description:
      'Cubrimos el pago de las indemnizaciones que pueda deber el asegurado por lesiones corporales involuntarias sufridas por sus empleados durante un accidente de trabajo ocurrido durante la vigencia del seguro y en el desarrollo de su actividad.También cubrimos los gastos de defensa y las fianzas judiciales necesarias para garantizar la responsabilidad civil o la libertad provisional, tras un siniestro cubierto por la póliza.Sin embargo, los costes no cubiertos por esta garantía no estarán cubiertos, y la cobertura está limitada por víctima según las condiciones específicas.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphRentalCivilLiability',
    name: 'Responsabilidad civil de alquiler',
    example:
      'Como inquilino de su despacho profesional, su equipo almacenado en el garaje sufrió daños durante la noche.  ➡️ Estás cubierto por la responsabilidad civil relacionada con este daño.',
    group: 'PI',
    shortDescription:
      'Cobertura de responsabilidad civil como propietario, poseedor o arrendatario de bienes vinculados a la actividad asegurada.',
    description:
      'Cubrimos la responsabilidad civil como propietario, titular de derechos de uso o arrendatario de bienes muebles e inmuebles vinculados a la actividad asegurada, siempre que: a) Los bienes se utilizan en el contexto de la actividad asegurada. b) El daño no sea consecuencia de negligencia grave.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphOperationalCivilLiability1',
    name: 'Responsabilidad civil de explotación',
    example:
      'Mientras está en un pequeño cliente para una sesión de capacitación, accidentalmente deja caer un servidor de computadora mientras se mueve. Es necesario cambiar el disco duro. ➡️ Cubrimos los costos de reparación o reemplazo de equipos averiados.',
    group: 'GL',
    shortDescription:
      'Cobertura de daños causados ​​a terceros durante el tiempo de trabajo, ajenos a la actividad profesional.',
    description:
      'Cubrimos los daños que usted cause a terceros, no directamente relacionados con la actividad profesional, siempre que: a) El daño se produce en el curso de la explotación del negocio.b) El daño se produce durante el tiempo de trabajo.',
    scope: new Set(['ECO', 'ENO', 'ETG', 'TECH'] as const),
  },
  {
    id: 'esRcphProfessionalIncapacityExpenses',
    name: 'Gastos de Inhabilitación Profesional',
    example:
      'Como consultor de gestión, tienes prohibido el ejercicio de tu actividad tras una decisión judicial firme por imprudencia en tu trabajo.  ➡️ Proporcionamos una compensación mensual limitada al ingreso mensual promedio que recibía antes de la condena.',
    group: 'PI',
    shortDescription:
      'Indemnización mensual en caso de prohibición judicial del ejercicio de la profesión, bajo condiciones.',
    description:
      'Se paga una indemnización mensual si el asegurado es condenado por decisión judicial firme en proceso penal a la prohibición de ejercer su profesión, según el Código Penal vigente, en las siguientes condiciones:a) El procedimiento esté vinculado a su actividad profesional. b) La condena resulte de imprudencia profesional.',
    scope: new Set(['ECO', 'ENO', 'ETG', 'GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphLegalAssistanceExpenses',
    name: 'Costos de asistencia jurídica',
    example:
      'Lo llaman como testigo en un juicio relacionado con un reclamo contra su negocio cubierto por un seguro.  ➡️ Recibirás una compensación por cada día que asistas al juzgado.',
    group: 'PI',
    shortDescription: 'Compensación por ser testigo en un juicio cubierto por la póliza.',
    description:
      'Si las personas que se enumeran a continuación asisten a un juicio como testigos en relación con un reclamo cubierto por esta póliza, se pagará una compensación en los siguientes montos, por cada día de asistencia requerida:a) Consejero, socio o directivo asegurado: 360 €b) Empleado asegurado: 360€Esta compensación se suma al límite máximo de compensación previsto en el contrato.',
    scope: new Set(['ECO', 'ENO', 'ETG', 'GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphPsychologicalAssistanceExpenses',
    name: 'Costos de asistencia psicológica.',
    example:
      'Como proveedor de servicios, usted es demandado por un error suyo en un proyecto, que causa un estrés importante a su cliente y es diagnosticado por un especialista. ➡️ Cubrimos los gastos de apoyo psicológico relacionados con esta situación.',
    group: 'PI',
    shortDescription: 'Cobertura de los costes de asistencia psicológica tras una reclamación contra un asegurado.',
    description:
      'Cubrimos los costes de asistencia psicológica resultantes directamente de un siniestro contra un asegurado, siempre que: a) El asegurado es una persona física. b) Los costes estén directamente relacionados con la reclamación.',
    scope: new Set(['ECO', 'ENO', 'ETG', 'GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphIntentionalOrMaliciousFaultOfEmployees',
    name: 'Mala conducta intencional o deliberada de los empleados',
    example:
      'Un empleado malversa fondos alterando facturas, causando pérdidas financieras a su negocio.  ➡️ Te compensamos dentro de los límites previstos en el contrato.',
    group: 'PI',
    shortDescription: 'Indemnización por pérdida económica por un acto deshonesto de sus empleados o subcontratistas.',
    description:
      'Cubrimos las pérdidas económicas causadas por un acto deshonesto, intencionado o fraudulento de sus empleados o subcontratistas, siempre que: a) El hecho se cometa en el marco de su actividad profesional. b) Se acredite la intención de perjudicarle y obtener ventaja personal.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphProfessionalCivilLiability',
    name: 'Responsabilidad civil profesional',
    example:
      'Como consultor de marketing digital, incumple negligentemente un plazo importante para una campaña publicitaria, lo que provoca una pérdida de ingresos para su cliente, que decide demandarlo por la pérdida.',
    group: 'PI',
    shortDescription:
      'Cobertura de daños causados ​​a terceros en el ejercicio de la actividad profesional, incluidos negligencias y errores.',
    description:
      'Cubrimos los daños causados ​​a terceros en el ejercicio de la actividad profesional, siempre que: a) El daño resulte de negligencia, error u omisión. b) Se excluyen los actos fraudulentos. Los daños son por ejemplo:- Negligencia o incumplimiento del deber/obligación.- Error, omisión o inexactitud negligente.- Violación de derechos de propiedad intelectual o industrial (copyright, patente, marca, etc.).- Violación de la confidencialidad o uso indebido de información confidencial.- Difamación.- Destrucción, deterioro, alteración o pérdida de los documentos/bienes confiados a su custodia.- Actos fraudulentos de sus socios, directores, empleados o subcontratistas.- Cualquier otra responsabilidad civil no expresamente excluida.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphWorldwideCoverage',
    name: 'Mundial',
    example:
      'Eres consultor en España y trabajas para un cliente ubicado en Estados Unidos. Este cliente se vuelve contra usted por incumplimiento de un plazo de entrega. ➡️ Si la opción mundial está activa, cubriremos tus gastos de defensa, así como los daños y perjuicios que tendrás que pagar a raíz de este plazo que no cumpliste.',
    group: 'Opciones',
    shortDescription: 'Garantías extendidas en todo el mundo, incluidos Estados Unidos y Canadá.',
    description:
      'Esta opción extiende sus garantías en todo el mundo, incluidos Estados Unidos y Canadá (por ejemplo, si se presenta un reclamo en su contra en un tribunal estadounidense). La opción se aplica a todas las garantías excepto a la protección legal.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'TECH'] as const),
  },
  {
    id: 'esRcphOperationalCivilLiability2',
    name: 'Responsabilidad civil de explotación',
    example:
      'Mientras está en un pequeño cliente para una sesión de capacitación, accidentalmente deja caer un servidor de computadora mientras se mueve. Es necesario cambiar el disco duro. ➡️ Cubrimos los costos de reparación o reemplazo de equipos averiados.',
    group: 'GL',
    shortDescription:
      'Cobertura de daños causados ​​a terceros durante el tiempo de trabajo, ajenos a la actividad profesional.',
    description:
      'Cubrimos los daños que usted cause a terceros, no directamente relacionados con la actividad profesional, siempre que: a) El daño se produce en el curso de la explotación del negocio.b) El daño se produce durante el tiempo de trabajo.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag'] as const),
  },
  {
    id: 'esRcphEmployerCivilLiability2',
    name: 'Responsabilidad civil del empleador',
    example:
      'Uno de sus empleados resulta lesionado en un accidente de trabajo ocurrido durante el período del seguro.  ➡️ También cubrimos gastos de defensa y fianzas legales necesarias.',
    group: 'GL',
    shortDescription:
      'Cobertura de indemnización por daños corporales sufridos por los empleados durante un accidente de trabajo.',
    description:
      'Cubrimos el pago de las indemnizaciones que pueda deber el asegurado por lesiones corporales involuntarias sufridas por sus empleados durante un accidente de trabajo ocurrido durante la vigencia del seguro y en el desarrollo de su actividad.También cubrimos los gastos de defensa y las fianzas judiciales necesarias para garantizar la responsabilidad civil o la libertad provisional, tras un siniestro cubierto por la póliza.Sin embargo, los costes no cubiertos por esta garantía no estarán cubiertos, y la cobertura está limitada por víctima según las condiciones específicas.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag'] as const),
  },
  {
    id: 'esRcphPostDeliveryCivilLiability2',
    name: 'Responsabilidad civil después de la entrega',
    example:
      'Eres consultor y tu cliente decide demandarte por negligencia, acusándote de haber configurado mal el sistema, lo que provocó la pérdida de datos importantes. Por lo tanto, eres responsable de los daños causados ​​después de tu misión. ➡️ Cubrimos las indemnizaciones que puedas deber, los costes de defensa y las fianzas legales para las reclamaciones cubiertas.',
    group: 'GL',
    shortDescription:
      'Cobertura por daños causados ​​por productos entregados o trabajos realizados, fuera de su control.',
    description:
      'Cubrimos las indemnizaciones que pueda deber el asegurado por los daños corporales, daños materiales o sus consecuencias, causados ​​involuntariamente a terceros por los productos entregados o los trabajos realizados, una vez que estos ya no estén bajo su control, durante el período asegurado y en el ejercicio. de su actividad profesional.También pagamos los costos de defensa y las fianzas judiciales relacionadas con un reclamo cubierto. Sin embargo, no se cubrirán los costes relacionados con piezas no cubiertas.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag'] as const),
  },
  {
    id: 'esRcphInsuredLossForDamageOnWebsite',
    name: 'Pérdidas del asegurado por daños en su página web',
    example:
      'Eres un consultor autónomo de RGPD. Tu negocio funciona muy bien gracias a una estrategia SEO cuidadosamente orquestada. Su sitio web ha sido pirateado y desconectado. ➡️ Cubrimos los costos de restauración de tu sitio web.',
    group: 'PI',
    shortDescription: 'Cubrimos los gastos de restauración del sitio web dañado por terceros, más allá del exceso.',
    description:
      'Si durante la vigencia del seguro la página web del asegurado resulta dañada o destruida por manipulación no autorizada por parte de terceros, el asegurador indemnizará al asegurado más allá de la franquicia por gastos de restauración, hasta el límite especificado en las condiciones particulares.',
    scope: new Set(['TECH'] as const),
  },
  {
    id: 'esRcphKeyPersonLoss',
    name: 'Pérdida de la persona clave (‘key person’)',
    example:
      'Eres una empresa de consultoría en ciberseguridad. Su asesor de proyecto resulta herido accidentalmente mientras conducía una motocicleta. Debe estar en ITT más de 3 meses y ya no puede realizar su misión.Para sustituirlo y mantener la actividad de su empresa, está obligado a contratar un nuevo consultor y acudir a una empresa de contratación para ello. ➡️Cubrimos los costos de reclutamiento y personal durante el tiempo que tu consultor no esté disponible.',
    group: 'PI',
    shortDescription:
      'Compensamos los costos de reemplazar a una persona clave perdida, incluidos el reclutamiento y la comunicación.',
    description:
      'El asegurador indemnizará al asegurado por la pérdida de una persona clave durante el período del seguro, previo consentimiento de éste. La compensación cubrirá los costos razonables incurridos por servicios de contratación, búsqueda de personal o relaciones públicas para limitar el impacto en el negocio del asegurado.Para esta garantía:1. La pérdida de una persona clave cubre los costes de los servicios de sustitución o gestión de la comunicación tras la incapacidad profesional o el fallecimiento de una persona clave.2. Una persona clave es alguien esencial para la gestión de la empresa, como directores, socios o funcionarios. No aplica deducible para esta extensión.',
    scope: new Set(['TECH'] as const),
  },
  {
    id: 'esRcphDamageToReputation',
    name: 'Daño a tu reputación',
    example:
      'Eres una empresa especializada en la integración de paquetes de software.Después de un error en un proyecto, uno de sus clientes le solicita reparaciones. Los gastos de defensa y posibles daños están cubiertos por la garantía “Falta o negligencia profesional”.Además, este cliente le comunica su error y su reputación se resiente: debe iniciar un plan de comunicación de crisis para restaurar su marca.➡️ Reembolsamos a los asesores de comunicación que utilices.',
    group: 'PI',
    shortDescription:
      'Cubrimos los costes de restauración de su reputación si ésta se ve gravemente afectada por un siniestro, con el consentimiento de la aseguradora.',
    description:
      'Si, durante la vigencia del seguro, el asegurado recibe un siniestro cubierto por el apartado "Reclamaciones contra el asegurado" y el asegurador considera que la reputación del asegurado ha sido gravemente perjudicada, el asegurador compensará los costes razonables, más allá de la franquicia, en que se haya incurrido. por el asegurado para restablecer su reputación, previo consentimiento del asegurador.',
    scope: new Set(['TECH'] as const),
  },
  {
    id: 'esRcphProjectFeesReimbursement',
    name: 'Devolución de honorarios de proyectos',
    example:
      'Mientras estás con un cliente pequeño, accidentalmente derribas el servidor de una computadora. Es necesario cambiar el disco duro. La empresa debe cerrar y pierde un día de facturación mientras se realiza la reposición, se vuelve en su contra. ➡️ Cubrimos los costos de reparación o reemplazo del equipo averiado así como cualquier daño que debas pagar.',
    group: 'PI',
    shortDescription:
      'Cubrimos reclamaciones por pérdidas causadas a terceros relacionadas con la operación de su negocio, incluidas lesiones corporales y daños a la propiedad.',
    description:
      'Esto incluye la Responsabilidad Operacional y del Producto, así como la Responsabilidad Operacional Post-Obra.El asegurador pagará hasta el monto especificado en el apartado “Lo que pagará el asegurador” si el asegurado recibe una reclamación por pérdidas causadas a terceros durante la vigencia de la póliza o dentro de los 24 meses siguientes a la cancelación del contrato, por causa de. :1. Daños corporales, daños materiales y pérdidas emergentes relacionadas con el funcionamiento del negocio.2. Los daños materiales y perjuicios emergentes causados ​​al propietario del local en calidad de inquilino.3. Daños corporales, daños materiales y pérdidas emergentes debidos a la actividad de los contratistas y subcontratistas del asegurado.4. Daños materiales a las redes públicas de telecomunicaciones o al dominio público radioeléctrico causados ​​por defectos en la instalación o mantenimiento de equipos de telecomunicaciones, hasta 600.000 € por siniestro/año.5. Los daños materiales, corporales y emergentes derivados de trabajos menores realizados en sus instalaciones, sujeto a:        tiene. No se requiere licencia municipal;        b. El presupuesto total del proyecto no supera los 1.500.000€.',
    scope: new Set(['TECH'] as const),
  },
  {
    id: 'esRcphSubsidiaryMotorVehicleLiability',
    name: 'Responsabilidad civil subsidiaria de vehículos a motor',
    example:
      'Su empresa utiliza vehículos para el servicio. En caso de accidente con empresa, empleado o vehículo alquilado.➡️ Cubrimos los daños causados. No están cubiertos los daños a los vehículos personales de los empleados.',
    group: 'GL',
    shortDescription:
      'Cobertura de responsabilidad por el uso de vehículos de motor cuando los empleados actúan en su nombre y en el ámbito de sus funciones.',
    description:
      'Cubrimos su responsabilidad civil por el uso de vehículos de motor, incluidos los de sus empleados o contratados para misiones de servicio, siempre que:a) Esta cobertura es superior a cualquier otra póliza de seguro.b) Los empleados actúan en su nombre y en el ámbito de sus funciones.c) No se cubren daños a vehículos de empleados o alquilados.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphSubsidiaryContractorLiability',
    name: 'Responsabilidad civil subsidiaria de contratistas o subcontratistas',
    example:
      'Su empresa utiliza un subcontratista para instalar equipos en la ubicación del cliente. Durante la instalación, un error cometido por el subcontratista provoca daños físicos importantes a la propiedad del cliente. El cliente responsabiliza a su empresa debido a la relación contractual. ➡️ Cubrimos los daños que excedan el límite de este seguro. Sin embargo, si el subcontratista no está asegurado, esta póliza no cubrirá los daños.',
    group: 'GL',
    shortDescription:
      'Cobertura de responsabilidad civil por daños causados ​​por contratistas o subcontratistas cuando usted conserve su derecho de repetición y estos tengan un seguro en vigor.',
    description:
      'Cubrimos su responsabilidad civil por daños causados ​​por contratistas o subcontratistas, siempre que:a) Usted puede ser considerado responsable por un vínculo contractual.b) Los contratistas o subcontratistas tengan vigente un seguro de responsabilidad civil.c) La cobertura sea superior a su seguro.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphAccidentalPollutionLiability',
    name: 'Responsabilidad civil por contaminación accidental',
    example:
      'Su empresa almacena temporalmente productos químicos en un almacén. Durante una entrega, uno de los contenedores se derramó accidentalmente, provocando una fuga que contaminó el suelo y el agua circundante. ➡️ Te cubrimos los daños causados ​​por este incidente, siempre que sea accidental, repentino e imprevisto. Sin embargo, si la contaminación hubiera ocurrido lenta y continuamente o en violación de las leyes ambientales, usted no estaría cubierto.',
    group: 'GL',
    shortDescription:
      'Cobertura de responsabilidad civil por contaminación accidental cuando la contaminación sea discontinua y cumpla con las leyes ambientales.',
    description:
      'Cubrimos su responsabilidad civil por contaminación accidental del suelo, agua o atmósfera, siempre que:a) La causa es accidental, repentina e imprevista.b) La contaminación no es continua, lenta o repetida.c) Se respetan las leyes y regulaciones ambientales.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphDamageToEntrustedObjects',
    name: 'Daños a objetos confiados',
    example:
      'Un cliente te confía un escritorio antiguo. Durante el transporte, un error de manipulación dañará gravemente el mueble. ➡️ Te cubrimos los gastos de reparación o reposición, siempre que la oficina esté a tu cargo en el momento del incidente. Sin embargo, la cobertura no aplica si el daño resulta de robo o falta manifiesta de cuidado en el manejo.',
    group: 'GL',
    shortDescription:
      'Cobertura por daños físicos a la propiedad bajo su custodia cuando los bienes se manejan con cuidado y no están sujetos a robo.',
    description:
      'Cubrimos daños físicos a bienes de terceros bajo su custodia o control, siempre que:a) El daño no sea causado por hurto o hurto.b) El daño no es consecuencia de una falta de cuidados básicos.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphCrossLiability',
    name: 'Responsabilidad civil cruzada',
    example:
      'Está trabajando en un lugar de trabajo con una empresa de plomería y uno de sus empleados daña su equipo. Aunque esto no se considera daño a “tercero” en el marco clásico de la responsabilidad civil profesional.➡️ Te cubrimos por daños entre profesionales.',
    group: 'GL',
    shortDescription:
      'Cobertura de daños recíprocos entre empresas en un proyecto conjunto cuando cada empresa tenga su propio seguro de responsabilidad civil.',
    description:
      'Cubrimos daños mutuos entre empresas que trabajan juntas en un proyecto, siempre que:a) Cada empresa tiene su propio seguro de responsabilidad civil.b) El daño se cause en el marco del proyecto conjunto.',
    scope: new Set(['GEST', 'MAC', 'MAN', 'MEDIA', 'MISC', 'secu', 'tag', 'TECH'] as const),
  },
  {
    id: 'esRcphPureFinancialLossLiability',
    name: 'Responsabilidad civil daños patrimoniales puros',
    example:
      'Su empresa de albañilería es responsable de los daños a la propiedad de una propiedad vecina debido a un error de diseño.➡️ Te cubrimos porque resulta de un hecho imprevisto y accidental. Sin embargo, no están cubiertos los daños relacionados con retrasos en la entrega, infracción de derechos de propiedad intelectual o errores en los servicios de consultoría.',
    group: 'GL',
    shortDescription:
      'Cobertura de daños materiales causados ​​a terceros cuando estos daños resulten de su responsabilidad legal y no estén vinculados a daños materiales o corporales previos.',
    description:
      'Cubrimos los daños materiales causados ​​a terceros, siempre que:a) No resulten de daños materiales o corporales previos.b) Se deben a hechos imprevistos y accidentales.c) Usted es legalmente responsable según la ley.',
    scope: new Set(['ECO', 'ENO', 'ETG'] as const),
  },
  {
    id: 'esRcphTheft',
    name: 'Robo',
    example:
      'Si se roban bienes bajo su supervisión o protegidos por sistemas de seguridad.  ➡️ Estás cubierto, siempre que no hayas sido negligente, que el robo no haya sido consecuencia de actos deshonestos y que ningún otro seguro cubra este robo.',
    group: 'PI',
    shortDescription:
      'Cobertura por robo de bienes bajo su supervisión, salvo que se compruebe negligencia o acto deshonesto de sus empleados o subcontratistas.',
    description:
      'Cubrimos el robo de bienes que le hayan sido confiados o puestos bajo su supervisión, o para los cuales haya diseñado, instalado, suministrado o mantenido sistemas de seguridad (incluidos los centros de monitoreo CRA), siempre que:a) No se prueba su negligencia.b) El robo no resulta de un acto deshonesto, ilícito o fraudulento cometido por sus empleados o cualquier otra persona o subcontratista contratados directamente por usted y bajo su supervisión.c) No tiene derecho a otra cobertura de seguro para este vuelo, excepto el exceso aplicable sobre el monto pagado por este otro seguro.',
    scope: new Set(['secu'] as const),
  },
  {
    id: 'esRcphOrganizationSaleAndExecutionOfCombinedServicesAndPackageTours',
    name: 'La organización, venta y realización de servicios combinados y paquetes turísticos.',
    example:
      'El cliente perjudicado decide demandar a la agencia de viajes por incumplimiento de sus obligaciones de seguridad y organización, acusándola de negligencia en la elección del proveedor de la excursión. Por tanto, la agencia es responsable de los daños corporales sufridos por el cliente, que reclama una indemnización por los gastos médicos, dolores y posible incapacidad laboral. ➡️ Estás cubierto por tu seguro profesional.',
    group: 'PI',
    shortDescription:
      'Organización, venta y realización de servicios combinados y paquetes turísticos, incluyendo recepción, asistencia y traslado de clientes.',
    description:
      'Abarcamos la organización, venta y realización de servicios combinados y paquetes turísticos, incluyendo la recepción, asistencia y traslado de clientes, siempre que: a) Los Servicios cumplen con la documentación escrita proporcionada por el Mayorista. b) Los servicios cumplen con los estándares de calidad establecidos.',
    scope: new Set(['tag'] as const),
  },
  {
    id: 'esRcphEntrustedOrSupervisedGoods',
    name: 'Bienes confiados o sujetos a vigilancia',
    example:
      'Como secretaria, si los documentos importantes de su cliente que están bajo su custodia se dañan o se pierden. ➡️ Estás cubierto por los costos de restauración o reposición, siempre que se demuestre negligencia y el incidente no haya sido resultado de un acto deshonesto por parte de tus subcontratistas.',
    group: 'PI',
    shortDescription:
      'Cobertura por daños o pérdida de bienes confiados a su supervisión, salvo negligencia o acto deshonesto comprobado.',
    description:
      'Cubrimos la destrucción, deterioro, alteración o pérdida de documentos o bienes confiados a usted o puestos bajo su supervisión, siempre que:a) El documento se encuentra bajo su custodia.b) Se compruebe la negligencia del asegurado.c) La pérdida no resulte de ningún acto deshonesto o fraudulento de sus empleados o subcontratistas.',
    scope: new Set(['secu'] as const),
  },
]
