import type { MrpGuaranteesType } from './mrph-guarantees-typenames.js'
import type { MrpDisplayableOptionalGuaranteeType } from './mrph-options-dimension.js'

export const mrphGuaranteeTitle: { [key in MrpGuaranteesType]: string } = {
  Attentat: 'Terrorisme',
  BrisGlace: 'Bris de glace',
  CatastropheNat: 'Catastrophes naturelles',
  DegatsEaux: 'Dégâts des eaux',
  DommagesElec: 'Dommages électriques',
  Emeute: 'Émeutes',
  EvenementsClim: 'Évènements climatiques',
  Incendie: 'Incendie',
  InformatiqueMachine: 'Informatiques et machines',
  RespCivOccupant: 'Responsabilité civile occupant',
  Vandalisme: 'Vandalisme',
  Vol: 'Vol & tentative de vol',
}

export const mrphGuaranteeDescriptionText: { [key in MrpGuaranteesType]: (isOwner: boolean) => string } = {
  Attentat: () => 'Vos biens sont endommagés par un attentat ou acte de terrorisme.',
  BrisGlace: () => 'Nous remboursons les bris accidentels de vitres : portes, fenêtres, et autres éléments vitrés.',
  CatastropheNat: () =>
    'Vos biens immobiliers ou mobiliers sont endommagés par une catastrophe naturelle (déclarée par un arrêté interministériel): tremblement de terre, tempête, éboulement, etc.',
  DegatsEaux: () => `Dommages causés par des fuites et infiltrations d’eau à vos locaux et à votre mobilier et matériel.
  Pour être couvert, pensez à interrompre la distribution d'eau en cas d'absence, à vidanger vos installations non chauffées pendant l'hiver et à stocker votre matériel à plus de 10 cm du sol.`,
  DommagesElec:
    () => `Dommages causés par des courts-circuits, surtensions ou sous-tensions à vos installations et appareils électriques.
  Exclusions principales : les dommages causés par l'usure ou un mauvais entretien.`,
  Emeute: () =>
    'Vos biens sont endommagés suite à une émeute, un mouvement populaire, un rassemblements armé ou non armé.',
  EvenementsClim: () => `Dommages causées par une tempête, de la grêle, neige, glace ou une inondation.
  Pour être couvert en cas de gel, pensez à vidanger vos installations non chauffées pendant l'hiver.`,
  Incendie:
    () => `Dommages causés à vos locaux et à votre mobilier et matériel par les incendies et leurs fumées, les explosions et les implosions, ou occasionnés par les secours.
  - Cette garantie couvre également les dommages causés par les chocs de véhicules à moteur.
  - Cette garantie ne couvre pas les brûlures provoquées par certains appareils, comme les fers à repasser.`,
  InformatiqueMachine: () => `Panne ou dysfonctionnement de vos machines professionnelles et informatiques.
  - Cette garantie ne couvre pas les dommages esthétiques.
  - Cette garantie ne couvre pas les dommages occasionnés par une utilisation ou une maintenance non conforme du matériel.`,
  RespCivOccupant: (isOwner) =>
    isOwner
      ? `Dommages matériels ou corporels causés au locataire suite à :
      • Un défaut d’entretien des bâtiments assurés
      • Un vice ou défaut qui empêchent l’usage des bâtiments assurés
      • Un trouble de jouissance ou une nuisance`
      : `Dommages matériels causés au propriétaire des bâtiments assurés.
  Cette garantie est étendue à la perte de loyers subie par le propriétaire des bâtiments si la responsabilité du sinistre incombe à l’assuré :
  - Dans la limite du temps nécessaire à la remise en état des bâtiments
  - Dans la limite d’une durée maximale de 2 ans à compter de la survenance du sinistre`,
  Vandalisme: () => 'Dégradations causées volontairement par un tiers et graffitis, inscriptions et affichages.',
  Vol: () => `Vol de vos biens mobiliers et détériorations de vos biens et de vos locaux lors d’une tentative de vol. Nous couvrons également le remplacement de vos clés et serrures ainsi que le vol de vos espèces, titres et valeurs en cas d'effraction de votre coffre-fort.
  - Pour être couvert, vous devez effectuer une déclaration de vol auprès de la police. Si vos biens sont retrouvés avant 30 jours, vous vous engagez à en reprendre possession et à rembourser vos indemnités perçues.
  - Trois sinistres maximum par année d’assurance.`,
}

export const mrphGuaranteeShortText: { [key in MrpGuaranteesType]: (isOwner: boolean) => string } = {
  Attentat: () => 'Dommages causés par un attentat ou des actes de terrorisme.',
  BrisGlace: () => 'Bris de vos portes vitrées, fenêtres, enseignes lumineuses et autres éléments vitrés.',
  CatastropheNat: () => 'Catastrophes naturelles : tremblements de terre, éboulements, affaissements de terrain, etc.',
  DegatsEaux: () => 'Dommages causés à vos locaux ou à leur contenu par des fuites et infiltrations d’eau.',
  DommagesElec: () =>
    'Dommages causés par des courts-circuits, surtensions ou sous-tensions à vos installations et appareils électriques.',
  Emeute: () => 'Dommages causés par des émeutes, des mouvements populaires, ou lors d’actes de sabotage.',
  EvenementsClim: () => 'Évènements climatiques : tempêtes, grêle, neige, glace, inondations, etc.',
  Incendie: () => 'Incendies, chutes de foudre, explosions, implosions, etc.',
  InformatiqueMachine: () =>
    'Pannes, dysfonctionnements et tout dommage à votre matériel professionnel et informatique.',
  RespCivOccupant: (isOwner) =>
    isOwner
      ? 'Dommages matériels et corporels causés au locataire des bâtiments assurés.'
      : 'Dommages matériels causés au propriétaire des bâtiments assurés.',
  Vandalisme: () => 'Nous couvrons les actes de vandalisme causés volontairement par un tiers sur votre matériel.',
  Vol: () => 'Nous remboursons les vols ainsi que les dégradations résultant de vols ou de tentatives de vol.',
}

export const mrphGuaranteeExampleText: { [key in MrpGuaranteesType]: (isOwner: boolean) => string } = {
  Attentat: () =>
    `Votre local ainsi que son mobilier sont endommagés par l’explosion d’une bombe artisanale. Nous remboursons la remise en état du local et le remplacement du mobilier. `,
  BrisGlace: () =>
    `Un matin, vous retrouvez la vitrine de votre local brisée. Nous prenons en charge le remplacement de la vitre. `,
  CatastropheNat: () =>
    `Un éboulement emporte une partie de votre local. L'état de catastrophe naturelle est reconnu. Nous remboursons la remise en état de votre local.`,
  DegatsEaux: () =>
    `Après s’être lavé les mains, une personne de votre entreprise ne referme pas entièrement le robinet de l’évier, occasionnant un débordement qui endommage votre parquet. Nous prenons en charge les réparations.`,
  DommagesElec: () =>
    `La foudre touche votre local et la surtension en résultant abîme votre matériel électrique. Nous prenons en charge les réparations.`,
  Emeute: () =>
    `Votre local ainsi que son mobilier sont endommagés par une émeute. Nous remboursons la remise en état du local et du mobilier.`,
  EvenementsClim: () =>
    `Une tempête arrache une partie de la toiture de votre local et la pluie abîme votre mobilier et votre parquet.`,
  Incendie:
    () => `Un incendie se déclare dans votre local. Vous et deux de vos employés tentez de contenir l’incendie avec vos extincteurs. Les pompiers arrivent quelques minutes après et parviennent à contrôler l’incendie.
    Nous remboursons les dégâts causés par l’incendie, par les fumées, par l’utilisation des extincteurs et par l’intervention des pompiers, de même que la recharge des extincteurs.`,
  InformatiqueMachine: () =>
    `Votre matériel professionnel tombe en panne et bloque votre activité. Nous prenons en charge sa réparation.`,
  RespCivOccupant: (isOwner) =>
    isOwner
      ? `Une personne de l’entreprise locataire se blesse dans le local résultant d’un défaut d’entretien de votre part.`
      : `Vous effectuez une manoeuvre délicate en déplacant de la marchandise dans votre local, ce qui endommage l’un des mur porteurs. Nous prenons en charge la remise en état du local.`,
  Vandalisme: () => `La façade extérieure de votre local est taguée pendant la nuit.`,
  Vol: () =>
    `La serrure de votre local est fracturée pendant la nuit et le cambrioleur s'empare d’ordinateurs portables et de disques durs externes. Nous prenons en charge le remboursement du matériel volé et le remplacement de la serrure.`,
}

export const mrphOptionalGuaranteeTitle: { [key in MrpDisplayableOptionalGuaranteeType]: string } = {
  ASSISTANCE: 'Assistance',
  PE: 'Pertes financières',
  PJ: 'Protection juridique',
  RCAL: 'Responsabilité civile après livraison',
  RCE: 'Responsabilité civile exploitation',
}

export const mrphOptionalGuaranteeDescriptionText: {
  [key in MrpDisplayableOptionalGuaranteeType]: (isOptionPeAvantageAvailable: boolean) => string
} = {
  ASSISTANCE: () =>
    `Nous couvrons suite à un sinistre sur votre local, la prise en charge des frais de retour anticipés sur le lieu du sinistre, les réparations provisoires pour certains sinistres, la recherche de prestataires pour la remise en état, nettoyage et gardiennage du local, la recherche et les frais de transport vers un local de remplacement provisoire.`,
  PE: (isOptionPeAvantageAvailable) =>
    `Nous garantissons les pertes financières provoquées par l’interruption ou la réduction de votre activité professionnelle suite à un dommage matériel.  ${
      isOptionPeAvantageAvailable
        ? `

  Cette option a deux formules :

  **Standard** :
  - Pertes d’exploitation : compensation en cas d’interruption ou réduction de votre activité.
  - Frais supplémentaires d’exploitation : prise en charge des frais engendrés pour maintenir votre activité en marche.
  - Frais et pertes additionnels : si des événements imprévus augmentent vos dépenses ou réduisent vos revenus.
  - Impossibilité d’accès : indemnisation si l’accès à votre établissement est compromis.

  **Avantage** :
  - Tout ce qui est inclus dans la formule Standard.
  - Perte de valeur vénale : nous compensons la perte de valeur de votre fonds de commerce post-sinistre.
  - Carence des fournisseurs : protection contre les perturbations de la chaîne d’approvisionnement.
  - Désaffection de la clientèle : indemnisation en cas de perte de clientèle due au sinistre.`
        : `

  Cette option couvre :

  - les pertes d’exploitation : compensation en cas d’interruption ou réduction de votre activité.
  - les frais supplémentaires d’exploitation : prise en charge des frais engendrés pour maintenir votre activité en marche.
  - les frais et pertes additionnels : si des événements imprévus augmentent vos dépenses ou réduisent vos revenus.
  - l'impossibilité d’accès : indemnisation si l’accès à votre établissement est compromis.`
    }`,
  PJ: () => `La protection juridique professionnelle vous protège en prévention d’un litige ou lors d’un litige ayant lieu dans le cadre de votre activité professionnelle.
  Nous vous conseillons, vous accompagnons dans la recherche d’une solution amiable, remboursons vos dépenses d’avocat et vous aidons à faire appliquer les décisions de justice. Elle s’exerce en France métropolitaine.`,
  RCAL: () => `Tous les dommages causés à ses clients ou à des tiers du fait de tout bien ou produit défectueux après sa livraison.
  Nous remboursons les frais engagés, comme le transport, le stockage, de destruction du produit, dans le cadre d’une opération de retrait.`,
  RCE: () => `Les garanties Responsabilité Civile vous protègent lorsque vous causez un dommage à un tiers.
  La RC exploitation est engagée lorsque le dommage survient dans la vie quotidienne de votre activité. Ses plafonds sont calculés par sinistre et par année d’assurance.`,
}

export const mrphOptionalGuaranteeShortText: { [key in MrpDisplayableOptionalGuaranteeType]: string } = {
  ASSISTANCE:
    'Nous prenons en charge les réparations provisoires, le nettoyage, le gardiennage du local ainsi que son remplacement provisoire.',
  PE: 'Nous vous couvrons en cas de baisse de chiffre d’affaires et des frais additionnels causés par l’interruption ou la réduction de ses activités professionnelles.',
  PJ: 'Un accompagnement pour vous aider sur toutes vos questions juridiques et rembourser vos frais d’avocats en cas de litige.',
  RCAL: 'Nous couvrons les dommages causées à des tiers du fait de tout bien ou produit défectueux après sa livraison.',
  RCE: 'Tout dommage causé à un tiers dans le quotidien de l’entreprise (événements, déplacement, etc.) mais hors de votre activité.',
}

export const mrphOptionalGuaranteeExampleText: { [key in MrpDisplayableOptionalGuaranteeType]: string } = {
  ASSISTANCE: `Votre local subit un dégât des eaux qui ne vous permet pas de continuer votre activité en toute sécurité. Nous prenons en charge la première intervention du plombier, le nettoyage des locaux, et le transport vers un local provisoire.`,
  PE: `Un incendie détruit une partie de votre local. Le temps de faire les travaux, vous devez interrompre votre activité pendant 2 mois. Nous vous remboursons la perte financière due à cette interruption d’activité.
  Si votre local perd définitivement en valeur suite à cet incendie, nous vous remboursons également cette perte.`,
  PJ: `Un de vos fournisseurs n’a pas respecté les délais de livraison et vous a fait perdre un important nouveau client. Nous vous accompagnons alors dans la recherche d'une solution, y compris dans le cadre d'un procès si nécessaire, et remboursons vos frais de justice.`,
  RCAL: `Suite à la livraison d’un de vos produits conforme et fonctionnel à l’envoie à l’un de vos clients basé au Portugal. Ce dernier vous recontacte pour vous informer que le produit ne fonctionne pas sans dommage visible. Nous prenons en charge les frais de retours du produit.`,
  RCE: `Un client se blesse en glissant sur le sol de l’entrée mouillé par la pluie de votre local. Nous vous protégeons et vous remboursons s'il se retourne contre vous et demande réparation.`,
}
