import type { RcProOptionalGuaranteesType } from '../quote-offer-information-dimension.js'
import type { RcProCyberOptionalGuaranteeType } from './rcph-quote-dimension.js'

export const rcProOptionalGuaranteeDescriptionsText: { [key in RcProOptionalGuaranteesType]: string } = {
  rce: `Nous vous protégeons lorsque vous causez un dommage à un tiers dans le cadre de la vie quotidienne de l’entreprise.

Les risques suivants sont notamment couverts :

* Risque locatif (dommage que vous causez à votre espace de travail en tant que locataire)
* Objets appartenant à des tiers
* Participation à des salons
* Intoxication alimentaire
* Vol par un de vos employés`,

  world: `Cette option étend vos garanties au monde entier, y compris aux États-Unis et au Canada (par exemple si une réclamation est faite contre vous devant une juridiction américaine).

Cette option est notamment utile si une partie de votre clientèle se trouve aux États-Unis.

Votre établissement doit se situer dans l’espace économique européen pour être protégé.

Les garanties du contrat sont les mêmes sur cette option, seule la protection juridique est spécifique. `,
  tdmi: `Nous couvrons le matériel professionel, en particulier : les ordinateurs, appareils photographiques (boitier et objectif), caméra, tables de mixages, drones, imprimantes, photocopieurs, télécopieurs, vidéoprojecteurs, téléphones fixes, tablettes tactiles, modems, et plus généralement tous les appareils informatiques, de bureautique et de télécommunication utilisés pour les besoins de vos activités professionnelles.

  Nous ne couvrons pas les téléphones portables et smartphones.`,

  cyber: `__La garantie Cyber vous couvre en cas d’attaque informatique :__

👉 __Assistance__ : Les honoraires des experts qui vous accompagnent dans la gestion de crise, les frais d’avocat, de gestion de communication de crise et de récupération des données.

👉 __Enquêtes__ et sanctions administratives : Les honoraires des avocats qui vont vous défendre lorsque que vous faites face à une enquête d’une autorité administrative (CNIL) ou d’une banque ainsi que le remboursement des amendes qui vous seront facturées.

👉 __Cyber extorsions__ : Le remboursement des rançons que vous avez dû payer sous la contrainte aux pirates ainsi que la perte d’exploitation éventuelle en découlant.`,

  mrpSmallOffice: `**ℹ️ Cette option n’est disponible que si vous êtes locataire et que la superficie du local est inférieure ou égale à 75 m².**

  Nous couvrons vos bureaux ou ateliers ainsi que les biens contenus. Cela signifie que vous bénéficiez d'une protection financière en cas de divers événements imprévus qui pourraient l’endommager, par exemple en cas d’incendie, de dégât des eaux...`,
}

export const rcProCyberOptionalGuaranteeDescriptionsText: { [key in RcProCyberOptionalGuaranteeType]: string } = {
  cyberDommagesTiers:
    '__En complément des garanties de base, vous pouvez étendre les garanties cyber pour couvrir les dommages que le sinistre causerait aux tiers :__ nous garantissons la prise en charge des frais de défense, dommages et intérêts, mesures correctrices mises en place pour limiter la cyber attaque au titre des dommages immatériels causés au tiers. Notamment en cas d’atteinte à la sécurité et à la confidentialité des données personnelles.',
  cyberDommagesPe:
    '__En complément des garanties de base, vous pouvez étendre les garanties cyber pour couvrir les dommages que votre activité pourraient subir__ : Les frais de notification, de centre d’appel, de surveillance, la PE et les frais supplémentaires d’exploitation.',
  cyberFraudePiratage:
    '__En complément des garanties de base, vous pouvez étendre les garanties cyber pour couvrir les dommages encourus à cause d’une Cyber-fraude et ou d’une surfacturation :__ Le remboursement des fraudes (vol, abus de confiance, etc.) sur les valeurs et biens vous appartenant ainsi que la surfacturation (factures télécoms).',
  cyberDefaillanceFournisseur:
    '__En extension de la garantie “Dommages subis par vous”, nous vous couvrons aussi en cas d’interruption de votre activité suite à une défaillance de votre fournisseur :__ les frais de notification, de centre d’appel, de surveillance, la PE et les frais supplémentaires d’exploitation.',
}

export const rcProOptionalGuaranteeExamplesText: { [key in RcProOptionalGuaranteesType]: string } = {
  rce: `Alors que vous êtes chez un petit client, vous faites accidentellement tomber un serveur informatique. Le disque dur doit être changé. L’entreprise doit fermer et perd un jour de chiffre d’affaires le temps que le remplacement soit effectué.

L’entreprise se retourne contre vous.

➡️ Nous prenons en charge les frais de réparation ou de remplacement du matériel cassé ainsi que les dommages et intérêts que vous devez verser. `,

  world: `Vous êtes consultant en France et travaillez pour un client situé aux États-Unis.

Ce client se retourne contre vous suite au non-respect d’un délai de livraison.

➡️ Si l’option monde entier est active, nous prenons en charge vos frais de défense, ainsi que les dommages et intérêts que vous devrez payer suite à ce délai que vous n’avez pas tenu.`,
  tdmi: 'Suite à une effraction dans votre voiture, votre ordinateur professionnel et votre matériel photographique ont été volés. ',
  cyber:
    'Un virus informatique chiffre toutes vos bases de données clients et vous ne pouvez plus accéder aux dossiers de vos projets. ',
  mrpSmallOffice: `Un incendie se déclare dans votre local. Vous tentez de contenir l’incendie avec vos extincteurs. Les pompiers arrivent quelques minutes après et parviennent à contrôler l’incendie. Les dégâts causés par l’incendie, par les fumées, par l’utilisation des extincteurs et par l’intervention des pompiers sont couverts, de même que la recharge des extincteurs.`,
}

export const rcProCyberOptionalGuaranteeExamplesText: { [key in RcProCyberOptionalGuaranteeType]: string } = {
  cyberDommagesPe:
    'Un virus informatique chiffre toutes vos bases de données clients et vous ne pouvez plus accéder aux dossiers de vos projets, ce qui vous force à mettre votre activité en pause le temps de la résolution',
  cyberDommagesTiers:
    'Un pirate informatique accède aux données sensibles de vos clients et les diffuse sur internet. ',
  cyberFraudePiratage:
    'Un pirate informatique trompe un de vos employés et ce dernier lui fait un virement bancaire en utilisant les comptes de l’entreprise. ',
  cyberDefaillanceFournisseur:
    'Un virus informatique chiffre toutes les bases de données de votre fournisseur et vous ne pouvez plus accéder aux dossiers de vos projets, ce qui vous force à mettre votre activité en pause le temps de la résolution.',
}

export const rcProOptionalGuaranteeTitle: { [key in RcProOptionalGuaranteesType]: string } = {
  rce: 'Responsabilité Civile Exploitation',
  world: 'Monde entier',
  tdmi: 'Protection matériel',
  cyber: 'Cyber',
  mrpSmallOffice: `Protection petit local`,
}

export const rcProCyberOptionalGuaranteeTitle: { [key in RcProCyberOptionalGuaranteeType]: string } = {
  cyberDommagesPe: 'Cyber - complément  "Dommages subis par vous"',
  cyberDefaillanceFournisseur: 'Cyber - complément "Défaillance Fournisseur"',
  cyberDommagesTiers: 'Cyber - complément  "Dommages aux tiers"',
  cyberFraudePiratage: 'Cyber - complément  "Cyber-fraude et surfacturation"',
}

export const rcProOptionalGuaranteeShortText: { [key in RcProOptionalGuaranteesType]: string } = {
  rce: 'Nous vous défendons et vous remboursons si vous causez un dommage à un tiers durant la vie quotidienne de votre entreprise.',
  world: 'Extension de vos garanties au monde entier, y compris aux États-Unis et au Canada.',
  tdmi: 'Nous couvrons votre matériel informatique en cas de dommage matériel et de vol. ',
  cyber:
    'Nous vous couvrons en cas d’attaque informatique, en particulier une assistance en cas de crise, d’enquête administrative et de cyber extorsion liés à l’attaque. ',
  mrpSmallOffice: `Nous couvrons vos bureaux ou ateliers ainsi que les biens contenus.`,
}
export const rcProCyberOptionalGuaranteeShortText: { [key in RcProCyberOptionalGuaranteeType]: string } = {
  cyberDommagesPe:
    'En complément de l’option cyber, nous couvrons les conséquences de l’attaque et les dommages sur votre entreprise. ',
  cyberDommagesTiers:
    'En complément de l’option cyber, nous vous couvrons en cas de dommage causé aux tiers en cas d’attaque. ',
  cyberFraudePiratage:
    'En complément de l’option cyber, nous vous couvrons en cas de cyberfraude et de surfacturation. ',
  cyberDefaillanceFournisseur:
    'En extension de la garantie “Dommages subis par vous”, nous vous couvrons en plus en cas d’interruption de votre activité suite à une défaillance de votre fournisseur. ',
}
