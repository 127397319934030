import { type RcphProduct } from './rcph-product-dimension.js'

import { type RcProVisibleGuaranteesForPolicy } from './rcph-guarantee-description.js'

export const rcProTechVisibleGuarantees: RcProVisibleGuaranteesForPolicy = {
  RecoursJuridique: 'P1',
  BienDocumentConfies: 'P1',
  ManquementsContract: 'P1',
  AtteinteReputation: 'P1',
  AtteinteDroitPropriete: 'P1',
  CoutsdeProjet: 'P2',
  FautesProOuNegligence: 'P2',
  RemplacementHommeCle: 'P2',
  RemboursementPresta: 'P2',
  PiratageSiteInternet: 'P2',
  PerteDocuments: 'P2',
  ContestationCreance: 'P2',
}

export const rcProTechStatementV2 = [
  `
  * Construction de véhicules automobiles, drones ou logiciels embarqués (sauf systèmes de navigation type GPS et info-divertissement)
  * Data centers
  * Moyens de paiement sécurisés, processeurs de paiement, logiciels bancaires et financiers
  * Jeux mobiles, applications mobiles grand public, applications mobiles dans le secteur médical
  * Logiciels BIM (Building Information Modeling)
  * Fabrication de matériel informatique ou de télécommunication
  * Prescriptions d'ordre technique assimilées à un bureau d’études techniques dans le domaine du bâtiment ou du génie civil
  * Systèmes qui régissent le fonctionnement et le contrôle des véhicules routiers et ferroviaires
  * Systèmes de manutention et de livraison des bagages dans les aéroports
  * Machines qui contrôlent directement un processus de fabrication (type SCADA)
  * Applications de biométrie, ou dans des environnements médicaux et laboratoires avec un risque direct de dommages corporels
  * Prestations relevant de professions réglementées soumises à obligation d'assurance.
  `,
]

export const rcProMacVisibleGuarantees: RcProVisibleGuaranteesForPolicy = {
  RecoursJuridique: 'P1',
  AtteinteDroitPropriete: 'P1',
  ManquementsContract: 'P1',
  AtteinteReputation: 'P1',
  BienDocumentConfies: 'P1',
  RemplacementHommeCle: 'P2',
  FautesProOuNegligence: 'P2',
  RemboursementPresta: 'P2',
  ContestationCreance: 'P2',
}

export const rcProMacStatementV2 = [
  `
* Prescription technique assimilée à de l’ingénierie ou bureau d’études techniques, dans le bâtiment, ou dans le génie civil
* Architecture d'intérieur
* Design industriel et de prototypes
* Développement ou commercialisation de logiciels BIM (Building Information Modeling)
* Fabrication de matériel informatique ou de télécommunication
* Fabrication et installation non sous-traitées de goodies, enseignes ou produits dérivés
* Développement de moyens de paiement sécurisés, processeur de paiement
* Fabrication de matériel informatique et/ou de télécommunication
* Prestations relevant de professions réglementées soumises à obligation d'assurance
  `,
]

export const rcProManVisibleGuarantees: RcProVisibleGuaranteesForPolicy = {
  RecoursJuridique: 'P1',
  ManquementsContract: 'P1',
  BienDocumentConfies: 'P1',
  AtteinteDroitPropriete: 'P1',
  AtteinteReputation: 'P1',
  RemboursementPresta: 'P2',
  RemplacementHommeCle: 'P2',
  FautesProOuNegligence: 'P2',
  ContestationCreance: 'P2',
}

export const rcProManStatementV2 = [
  `
* Conseil en décontamination, gestion de déchets ou réalisation d'audits techniques d'impact environnemental
* Conseils en investissement financier ou en gestion de patrimoine
* Bureau d’études techniques ou prescription technique assimilée à de l’ingénierie
* Réalisation, suivi ou réception de travaux de bâtiment ou de génie civil
* Conseil en brevets
* Conseil en optimisation de charges fiscales et sociales (y compris crédit d’impôt recherche)
* Prestations relevant de professions réglementées soumises à obligation d'assurance
`,
]

export const rcProMediaVisibleGuarantees: RcProVisibleGuaranteesForPolicy = {
  RecoursJuridique: 'P1',
  AtteinteDroitPropriete: 'P1',
  AtteinteReputation: 'P1',
  ManquementsContract: 'P1',
  BienDocumentConfies: 'P1',
  RemplacementHommeCle: 'P2',
  FautesProOuNegligence: 'P2',
  RemboursementPresta: 'P2',
  ContestationCreance: 'P2',
}

export const rcProMediaStatementV2 = [
  `
* Professions réglementées soumises à obligation d’assurance
* Conseil juridique
* Agent artistique, manager artistique, impresario ou éditeur musical
* Cirque
* Agence de presse nationale ou mondiale
* Télévision ou radio nationale (y compris sur le web)
* Presse people ou d’investigation
* Réalisation, production ou diffusion dans les domaines suivants : téléfilms, séries, cinéma, programmes d’investigation, de débats politiques, de téléréalité et de jeux télévisuels.`,
  `Vous n’intervenez pas non plus dans l’organisation, la production ou la production des évènements suivants :
* Spectacles tauromachiques
* Manifestations de soutien, de contestation ou d'opposition sur la voie publique
* Événements se déroulant aux USA/Canada ou dans un pays soumis à sanctions nationales, européennes ou internationales
* Rave parties, concert grand public de musique techno, rap, hard rock ou heavy metal
* Évènements impliquant des structures gonflables ou des feux d'artifices
* Spectacles dans lesquels se produisent des artistes connus pour leurs propos provocateurs ou pornographiques.
  `,
]

export const rcProMiscVisibleGuarantees: RcProVisibleGuaranteesForPolicy = {
  RecoursJuridique: 'P1',
  AtteinteReputation: 'P1',
  BienDocumentConfies: 'P1',
  ManquementsContract: 'P1',
  FautesProOuNegligence: 'P1',
  RemplacementHommeCle: 'P2',
  AtteinteDroitPropriete: 'P2',
  RemboursementPresta: 'P2',
  ContestationCreance: 'P2',
}

export const rcProMiscStatementV2 = [
  `
* Diagnostics immobiliers
* Conseil juridique
* Conseil en investissement financier ou en gestion de patrimoine
* Bureau d’études techniques ou prescription technique assimilée à de l’ingénierie
* Réalisation, suivi ou réception de travaux de bâtiment ou de génie civil
* Conseil en optimisation de charges fiscales et sociales (y compris crédit d’impôt recherche)
* Rédaction d’actes sous seing privé (à l’exception des mandats de vente)
* Maniement de fonds`,
]

export const rcProMiscBieVisibleGuarantees: RcProVisibleGuaranteesForPolicy = {
  RecoursJuridique: 'P1',
  AtteinteReputation: 'P1',
  ManquementsContract: 'P1',
  FautesProOuNegligence: 'P1',
  BienDocumentConfies: 'P1',
  RemplacementHommeCle: 'P2',
  AtteinteDroitPropriete: 'P2',
  RemboursementPresta: 'P2',
  ContestationCreance: 'P2',
}

export const rcProMiscBieStatementV2 = [
  `Vous pouvez prouver votre compétence dans l’activité assurée :
* Soit en montrant que vous possédez un diplôme correspondant à votre activité.
* Soit en justifiant d'un cycle de formation de cent heures minimum correspondant à votre activité.
* Soit en prouvant que vous pratiquez votre activité depuis au moins 6 ans.
  `,
]

export const rcphProductVisibleGuarantees: { [key in RcphProduct]: RcProVisibleGuaranteesForPolicy } = {
  TECH: rcProTechVisibleGuarantees,
  MAC: rcProMacVisibleGuarantees,
  MAN: rcProManVisibleGuarantees,
  MEDIA: rcProMediaVisibleGuarantees,
  MISC: rcProMiscVisibleGuarantees,
  MISCBIE: rcProMiscBieVisibleGuarantees,
  SECU: {
    RecoursJuridique: 'P1',
    ManquementsContract: 'P1',
    AtteinteReputation: 'P1',
    AtteinteDroitPropriete: 'P1',
    FautesProOuNegligence: 'P2',
    RemplacementHommeCle: 'P2',
    RemboursementPresta: 'P2',
    ContestationCreance: 'P2',
  },
  TAG: {
    RecoursJuridique: 'P1',
    ManquementsContract: 'P1',
    AtteinteReputation: 'P1',
    AtteinteDroitPropriete: 'P1',
    FautesProOuNegligence: 'P2',
    RemplacementHommeCle: 'P2',
    RemboursementPresta: 'P2',
    ContestationCreance: 'P2',
  },
  ECOM: {
    RecoursJuridique: 'P1',
    ManquementsContract: 'P1',
    AtteinteReputation: 'P1',
    AtteinteDroitPropriete: 'P1',
    FautesProOuNegligence: 'P2',
    RemplacementHommeCle: 'P2',
    RemboursementPresta: 'P2',
    ContestationCreance: 'P2',
  },
}
