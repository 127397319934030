export const mrphGuaranteesTypeNames = [
  'Incendie',
  'DommagesElec',
  'DegatsEaux',
  'Vol',
  'Vandalisme',
  'EvenementsClim',
  'BrisGlace',
  'CatastropheNat',
  'Attentat',
  'Emeute',
  'InformatiqueMachine',
  'RespCivOccupant',
] as const

export type MrpGuaranteesType = (typeof mrphGuaranteesTypeNames)[number]
