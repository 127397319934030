import type { RcProGuaranteesTypes } from '../quote-offer-information-dimension.js'
import type { RcProGuaranteeType } from './rcph-quote-dimension.js'

export const rcProGuaranteeLabels: { [guarantee in RcProGuaranteeType]: string } = {
  world: 'Monde entier',
  rcp: 'Responsabilité Civile Professionnelle',
  rce: 'RC Exploitation',
  pj: 'Protection Juridique',
  tdmi: 'Option Tout Dommage Matériel Informatique',
  mrpSmallOffice: 'Option Protection petits locaux',
  cyber: 'Option Cyber',
  cyberDommagesPe: 'Cyber - complément  "Dommages subis par vous"',
  cyberDefaillanceFournisseur: 'Cyber - complément  "Défaillance Fournisseur"',
  cyberDommagesTiers: 'Cyber - complément  "Dommages aux tiers"',
  cyberFraudePiratage: 'Cyber - complément  "Cyber-fraude et surfacturation"',
}

export const rcProGuaranteeNames: { [key in RcProGuaranteesTypes]: string } = {
  RemboursementPresta: 'Restitution des honoraires',
  ManquementsContract: 'Manquements contractuels',
  RecoursJuridique: 'Protection juridique',
  CoutsdeProjet: 'Coûts déjà engagés',
  ContestationCreance: 'Contestation de créance',
  RemplacementHommeCle: 'Remplacement homme clé',
  FautesProOuNegligence: 'Fautes ou négligences',
  AtteinteReputation: 'Atteinte à votre réputation',
  PerteDocuments: 'Perte de vos documents',
  PiratageSiteInternet: 'Piratage de votre site internet',
  BienDocumentConfies: 'Perte des biens confiés',
  AtteinteDroitPropriete: 'Propriété intellectuelle',
}
