import { allEsRcphGuaranteesAndOptions } from '@orus.eu/dimensions'
import { guaranteeImages } from '../../../../../organisms/guarantee/guarantee-images'

export function getEsRcphGuaranteesAndOptions(): Array<{
  name: string
  options: Array<{
    icon: string
    description: string
  }>
}> {
  const allGroups = new Set(allEsRcphGuaranteesAndOptions.map((guarantee) => guarantee.group))
  return [...allGroups].map((group) => ({
    name: group,
    options: allEsRcphGuaranteesAndOptions
      .filter((guarantee) => guarantee.group === group)
      .reduce(
        (options, guarantee) =>
          options.some(({ description }) => description === guarantee.name)
            ? options
            : [...options, { icon: guaranteeImages[guarantee.id], description: guarantee.name }],
        new Array<{ icon: string; description: string }>(),
      ),
  }))
}
